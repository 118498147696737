/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/home'
    },
    {
        id: 'tutors',
        title: 'Tutors',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/tutors'
    },
    {
        id: 'students',
        title: 'Students',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/students'
    },
    {
        id: 'booking',
        title: 'Bookings',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/booking'
    },
    {
        id: 'wallet',
        title: 'Wallet',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/wallet'
    },
    {
        id: 'withdraw-request',
        title: 'Withdraw Request',
        type: 'basic',
        icon: 'heroicons_outline:cash',
        link: '/withdraw-request'
    },
    // {
    //     id: 'contact-us',
    //     title: 'Contact us',
    //     type: 'basic',
    //     icon: 'heroicons_outline:calendar',
    //     link: '/contact-us'
    // },

];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/home'
    },
    {
        id: 'tutors',
        title: 'Tutors',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/tutors'
    },
    {
        id: 'students',
        title: 'Students',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/students'
    },
    {
        id: 'booking',
        title: 'Booking',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/booking'
    },
    {
        id: 'wallet',
        title: 'Wallet',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/wallet'
    },
    {
        id: 'withdraw-request',
        title: 'withdraw request',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/withdraw-request'
    },

];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/home'
    },
    {
        id: 'tutors',
        title: 'Tutors',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/tutors'
    },
    {
        id: 'students',
        title: 'Students',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/students'
    },
    {
        id: 'booking',
        title: 'Booking',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/booking'
    },
    {
        id: 'wallet',
        title: 'Wallet',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/wallet'
    },
    {
        id: 'withdraw-request',
        title: 'withdraw request',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/withdraw-request'
    },

];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/home'
    },
    {
        id: 'tutors',
        title: 'Tutors',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/tutors'
    },
    {
        id: 'students',
        title: 'Students',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/students'
    },
    {
        id: 'booking',
        title: 'Booking',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/booking'
    },
    {
        id: 'wallet',
        title: 'Wallet',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/wallet'
    },
    {
        id: 'withdraw-request',
        title: 'withdraw request',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/withdraw-request'
    },

];
