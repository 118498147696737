import { NgModule } from '@angular/core';
import { OntuteService } from './ontute.service';

@NgModule({
    // imports  : [
    //     HttpClientModule
    // ],
    providers: [
        OntuteService,
        // {
        //     provide : HTTP_INTERCEPTORS,
        //     useClass: AuthInterceptor,
        //     multi   : true
        // }
    ]
})
export class OntuteModule
{
}
