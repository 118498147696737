import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { OntuteService } from 'app/core/ontute/ontute.service';
import * as moment from 'moment';

@Component({
    selector: 'app-order-filters',
    templateUrl: './order-filters.component.html',
    styleUrls: ['./order-filters.component.scss']
})
export class OrderFiltersComponent implements OnInit {

    @Input() isTutor = false;
    @Input() showTutor = true;
    formGroup;
    subjects = [];
    tutors = [];

    @Output() filterChange = new EventEmitter<any>();
    constructor(private _formBuilder: FormBuilder,
        private ontuteService: OntuteService) { }

    ngOnInit() {
        this.formGroup = this._formBuilder.group({
            subject: ['',],
            tutor: ['',],
            date: ['',],
            // degrees: this._formBuilder.array([this.createDegreeFormGroup()]),
        });
        this.ontuteService.getAdminTutorSubjectList().subscribe((result) => {
            this.subjects =  result.data['getAdminTutorSubjectList'];
        });
    }

    onSubjectSelection(event) {
        const selectedSubject = event.value;
        this.applyFilter();
    }

    onTutorSelection(event) {
        // let selectedSubject = event.value
        this.applyFilter();
    }

    OnDateChange() {
        this.applyFilter();
    }

    applyFilter() {
        const value = this.formGroup.value;
        this.filterChange.emit({
            subject: (value && value.subject && value.subject.id) ? value.subject.id : '',
            tutor: (value && value.tutor && value.tutor.id) ? value.tutor.id : '',
            date: (value && value.date) ? moment(value.date).valueOf() : 0
        });
    }

}
