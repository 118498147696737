<div class="flex flex-col max-w-200 md:min-w-160 max-h-screen -m-6">
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary"
    >
        <div class="text-lg font-medium">{{ data?.title }}</div>
        <button mat-icon-button (click)="saveAndClose()" [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"
            ></mat-icon>
        </button>
    </div>

    <!-- Educatiom form -->
    <form [formGroup]="orderCancelForm" class="p-6 sm:p-8 overflow-y-auto">
        <mat-form-field *ngIf="data.title === 'Cancel Order'" class="w-full">
            <mat-label>Reason for Cancellation</mat-label>
            <textarea #reason [formControlName]="'reason'" maxlength="150"  matInput placeholder="Reason for Cancellation"></textarea>
            <mat-hint>{{reason.value.length}} / 150</mat-hint>
          </mat-form-field>
        <!-- Education -->
        <div>{{ data?.description }}</div>
        <div>This action can't be undone.</div>

        <!-- Actions -->
        <div
            class="flex flex-col sm:flex-row sm:items-center justify-end mt-4 sm:mt-6"
        >
            <div class="flex items-center mt-4 sm:mt-0">
                <!-- Submit -->
                <button
                    class="order-first m-3 sm:order-last"
                    mat-flat-button
                    [color]="'primary'"
                    (click)="saveAndClose()"
                >
                    Cancel
                </button>
                <button
                    class="order-first m-3 sm:order-last"
                    mat-flat-button
                    [color]="'primary'"
                    (click)="submit()"
                >
                    Submit
                </button>
            </div>
        </div>
    </form>
</div>
