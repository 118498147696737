import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OntuteService } from 'app/core/ontute/ontute.service';

@Component({
    selector: 'app-order-status-dialog',
    templateUrl: './order-status-dialog.component.html',
    styleUrls: ['./order-status-dialog.component.scss']
})
export class OrderStatusDialogComponent implements OnInit {

    quillModules: any = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
            ['clean']
        ]
    };
    orderCancelForm: FormGroup;

    orderStatus;

    constructor(
        public matDialogRef: MatDialogRef<OrderStatusDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder,
        private ontuteService: OntuteService

    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        this.orderCancelForm = this._formBuilder.group({
            reason: ['', Validators.required],
        });

    }

    /**
     * Save and close
     */
    saveAndClose(): void {
        // Close the dialog
        this.matDialogRef.close(false);
    }


    submit() {
        if (this.data.title === 'Cancel Order') {
            if (this.orderCancelForm.invalid) {
                return;
            } else {
                this.matDialogRef.close(true);
                this.ontuteService.markBookingAsCancelledStudent(this.data.orderId, this.orderCancelForm.controls.reason.value).subscribe((result) => {
                });
            }
        } else {
            this.matDialogRef.close(true);
        }


    }

}
