import gql from 'graphql-tag';

/**
 * Queries
 */

export const getTutorSubjectDetails = gql`
    query getTutorSubjectDetails($tutorSubjectId: String!) {
        getTutorSubjectDetails(tutorSubjectId: $tutorSubjectId) {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                level {
                    id
                    name
                }
                rate
            }
        }
    }
`;

export const updateTutorSubjectByAdmin = gql`
    mutation updateTutorSubjectByAdmin($tutorSubjectId: String!, $userId: String!, $subjectLevelRate: [InputLevelRate!]!){
        updateTutorSubjectByAdmin(tutorSubjectId: $tutorSubjectId, userId: $userId, subjectLevelRate: $subjectLevelRate){
            id
        }
    }
`;

export const removeTutorSubjectByAdmin = gql`
    mutation removeTutorSubjectByAdmin($userId: String!, $tutorSubjectId: String!){
        removeTutorSubjectByAdmin(userId: $userId, tutorSubjectId: $tutorSubjectId){
            id
        }
    }
`;

export const createMultipleBookingByAdmin = gql`
    mutation createMultipleBookingByAdmin($studentId: String!, $tutorId: String!, $createBookingInput: [CreateMultipleBookingInputByAdmin!]!, $isBypass: Boolean!){
        createMultipleBookingByAdmin(studentId: $studentId, tutorId: $tutorId, createBookingInput: $createBookingInput, isBypass: $isBypass){
            sessionId
            bookings{
                id
                batch_number
            }
        }
    }
`;

export const createBookingSessionIdByAdmin = gql`
    mutation createBookingSessionIdByAdmin($batch_number: String!){
        createBookingSessionIdByAdmin(batch_number: $batch_number)
    }
`;

export const getDraftBookingsAdmin = gql`
    query getDraftBookingsAdmin(
        $status: String
        $date: Float
        $tutor: String
        $subject: String
        $limit: Float!
        $pageIndex: Float!
    ) {
        getDraftBookingsAdmin(
            status: $status
            date: $date
            tutor: $tutor
            subject: $subject
            limit: $limit
            pageIndex: $pageIndex
        ) {
            data {
                id
                batch_number
                tutor {
                    id
                    firstName
                    lastName
                }
                student {
                    id
                    firstName
                    lastName
                }
                subject {
                    id
                    name
                }
                level {
                    id
                    name
                }
                totalDuration
                startTime
                endTime
                orderAmount
                tutorAmount
                paymentType
                reasonForCancellation
                orderNo
                createdAt
                status
            }
            count
        }
    }
`;

export const getTutorScheduleByAdmin = gql`
    query getTutorScheduleByAdmin($userId: String!){
        getTutorScheduleByAdmin(userId: $userId){
            id
            day
            slot{
                startTime
                endTime
                id
            }
            isAvailable
            generatedSlots
        }
    }
`;

export const updateTutorScheduleByAdmin = gql`
    mutation updateTutorScheduleByAdmin($userId: String!, $updateScheduleInput: [UpdateScheduleInput!]!) {
        updateTutorScheduleByAdmin(userId: $userId, updateScheduleInput: $updateScheduleInput)
    }
`;

export const changeOrderStatusByAdmin = gql`
    mutation changeOrderStatusByAdmin($status: String!, $orderId: String!, $payVia: String){
        changeOrderStatusByAdmin(status: $status, orderId: $orderId, payVia: $payVia)
    }
`

export const changeOrderStatusUpcommingBatchNumberByAdmin = gql`
    mutation changeOrderStatusUpcommingBatchNumberByAdmin($batch_number: String!, $payVia: String!){
        changeOrderStatusUpcommingBatchNumberByAdmin(batch_number: $batch_number, payVia: $payVia)
    }
`;

export const updateBookingStatusAfterPayment = gql`
    mutation updateBookingStatusAfterPayment($bookingId: String!) {
        updateBookingStatusAfterPayment(bookingId: $bookingId) 
    }
`;

export const markBookingAsCancelledStudent = gql`
    mutation markBookingAsCancelledStudent($bookingId: String!, $reasonForCancellation:String!){
        markBookingAsCancelledStudent(bookingId:$bookingId, reasonForCancellation: $reasonForCancellation)
    }
`;

export const createReview = gql`
    mutation createReview($review: CreateReviewInput!) {
        createReview(createReviewInput: $review) {
            id
            booking {
                id
                tutor {
                    id
                    firstName
                    lastName
                }
                student {
                    id
                    firstName
                    lastName
                }
            }
            rating
            description
            status
        }
    }
`;

export const updateTutorReview = gql`
    mutation UpdateTutorReview($reviewId: String!, $createReviewInput: CreateReviewInput!){
        updateTutorReview(reviewId: $reviewId, createReviewInput: $createReviewInput){
            id,
            status,
            rating,
            description,
            title
        }
    }
`;

export const getAllSubjectsByGroup = gql`
    query getAllSubjectsByGroup {
        getAllSubjectsByGroup {
            subjectGroup {
                id
                subjectGroupName
            }
            subjects {
            id
            subjectName
            }
        }
    }
`;

export const getTutorEducation = gql`
    query getTutorEducationList {
        getTutorEducationList {
            id
            educationLevel {
                id
                name
            }
            degreeProgram {
                id
                name
            }
        }
    }
`;

export const getTutorSubjectList = gql`
    query getTutorSubjectList {
        getTutorSubjectList {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                level {
                    id
                    name
                }
                rate
            }
        }
    }
`;

export const getAdminTutorSubjectList = gql`
    query getAdminTutorSubjectList {
        getAdminTutorSubjectList {
            id
            name
        }
    }
`;

export const getAllVerifiedTutors = gql`
    query getAllVerifiedTutors {
        getAllVerifiedTutors {
            id
            firstName
            lastName
            description
            avatar
            onlineCoaching
            inPerson
            createdAt
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        name
                    }
                    rate
                }
            }
        }
    }
`;

export const searchTutorBySubject = gql`
    query searchTutorBySubject($subjectName: String!) {
        searchTutorBySubject(subjectName: $subjectName) {
            id
            firstName
            lastName
            avatar
            description
            onlineCoaching
            inPerson
            createdAt
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        name
                    }
                    rate
                }
            }
        }
    }
`;

export const searchTutorByName = gql`
    query searchTutorByName($tutorName: String!) {
        searchTutorByName(tutorName: $tutorName) {
            id
            firstName
            lastName
            avatar
            description
            onlineCoaching
            inPerson
            createdAt
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        name
                    }
                    rate
                }
            }
        }
    }
`;

export const getAParticularStudent = gql`
    query getAParticularStudent($studentId: String!) {
        getAParticularStudent(studentId: $studentId) {
            id
            firstName
            lastName
            description
            onlineCoaching
            inPerson
            overallRating
            isVerified
            isBlocked
            avatar
            dob
            gender
            utcOffset
            city {
                id
                name
            }
            country {
                id
                name
            }
            contactNo
            cnic_image_1
            cnic_image_2
            email
            identityDocuments
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        id
                        name
                    }
                    rate
                }
            }
            tutorEducations {
                educationLevel {
                    name
                }
                degreeProgram {
                    name
                }
                fileLink
            }
        }
    }
`;

export const getAParticularTutorAdmin = gql`
    query getAParticularTutorAdmin($tutorId: String!) {
        getAParticularTutorAdmin(tutorId: $tutorId) {
            id
            firstName
            lastName
            description
            onlineCoaching
            inPerson
            overallRating
            numberOfReviews
            isVerified
            isBlocked
            avatar
            contactNo
            utcOffset
            cnic_image_1
            cnic_image_2
            language{id name}
            document_id
            country{
                id
                name
            }
            city{
                id
                name
            }
            gender
            email
            identityDocuments
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        id
                        name
                    }
                    rate
                }
            }
            tutorEducations {
                id
                educationLevel {
                    name
                }
                degreeProgram {
                    name
                }
                fileLink
            }
        }
    }
`;

export const getAParticularTutor = gql`
    query getAParticularTutor($tutorId: String!) {
        getAParticularTutorAdmin(tutorId: $tutorId) {
            id
            firstName
            lastName
            description
            paymentMethod
            payoneerEmail
            tutorBankDetails{
                id
                accountTitle
                accountNumber
                iban
                bankName
                branchName
                swiftCode
                ukSortCode
                ifscCode
                institutionNumber
                transitNumber
                accountType

            }
            onlineCoaching
            inPerson
            overallRating
            numberOfReviews
            isVerified
            isBlocked
            avatar
            contactNo
            cnic_image_1
            cnic_image_2
            document_id
            email
            identityDocuments
            city{
                id
                name
            }
            country{
                id
                name
            }
            gender
            utcOffset
            language{
                id
                name
            }
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        id
                        name
                    }
                    rate
                }
            }
            tutorEducations {
                id
                educationLevel {
                    name
                    id
                }
                degreeProgram {
                    name
                    id
                }
                fileLink
            }
        }
    }
`;

export const getAllSubject = gql`
    query getAllSubject {
        getAllSubject {
            id
            name
            levels {
                id
                name
            }
        }
    }
`;

export const getReviews = gql`
    query getTutorReviews($tutorId: String!) {
        getTutorReviews(tutorId: $tutorId) {
            id
            rating
            description
            title
            booking {
                id
                student {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const getLevelsOfParticularSubject = gql`
    query getLevelsOfParticularSubject($subjectId: String!) {
        getLevelsOfParticularSubject(subjectId: $subjectId) {
            id
            name
        }
    }
`;

export const getAllEducationLevel = gql`
    query getAllEducationLevel {
        getAllEducationLevel {
            id
            name
        }
    }
`;

export const getProgramsOfParticularEducation = gql`
    query getProgramsOfParticularEducation($educationId: String!) {
        getProgramsOfParticularEducation(educationId: $educationId) {
            id
            name
        }
    }
`;

export const getBasicAccountDetails = gql`
    query getBasicAccountDetails {
        getBasicAccountDetails {
            id
            firstName
            lastName
            description
            email
            contactNo
            gender
            city {
                id
                name
            }
            country {
                id
                name
            }
            language {
                id
                name
            }
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    level {
                        name
                    }
                    rate
                }
            }
            tutorEducations {
                educationLevel {
                    name
                }
                degreeProgram {
                    name
                }
            }
            inPerson
            onlineCoaching
        }
    }
`;

export const getAllCountries = gql`
    query getAllCountries {
        getAllCountries {
            id
            name
        }
    }
`;

export const getAllCities = gql`
    query getAllCities {
        getAllCities {
            id
            name
        }
    }
`;

export const getCitiesOfParticularCountry = gql`
    query getCitiesOfParticularCountry($countryId: String!) {
        getCitiesOfParticularCountry(countryId: $countryId) {
            id
            name
        }
    }
`;

export const updateTutorDocumentsByAdmin = gql`
    mutation updateIdentityDocumentTutorByAdmin($userId: String!, $cnicImage1: String!, $cnicImage2: String!, $documentNumber: String!){
        updateIdentityDocumentTutorByAdmin(userId: $userId, cnicImage1: $cnicImage1, cnicImage2: $cnicImage2, documentNumber: $documentNumber){
            cnic_image_1,
            cnic_image_2,
            document_id
        }
    }
`;

export const getAllLanguages = gql`
    query getAllLanguages {
        getAllLanguages {
            id
            name
        }
    }
`;

export const getCommonDropDownData = gql`
    query getCommonDropDownData {
        getAllLanguages {
            id
            name
        }

        getAllCountries {
            id
            name
        }
    }
`;

export const updateUserDetailAdmin = gql`
    mutation updateUserDetailByAdmin($userId: String!, $userDetailInput: UserDetailsUpdateDTO!) {
        updateUserDetailByAdmin(userId: $userId, userDetailInput: $userDetailInput) {
            id
            firstName
            lastName
            description
            email
            contactNo
            avatar
            gender
            city {
                id
                name
            }
            country {
                id
                name
            }
            language {
                id
                name
            }
            userRole {
                id
            }
            inPerson
            onlineCoaching
        }
    }
`;

/**
 * Mutations
 */

export const onboardTutor = gql`
    mutation onboardTutor($tutorDetailInput: OnboardTutorDTO!) {
        onboardTutor(tutorDetailInput: $tutorDetailInput) {
            id
            firstName
            lastName
        }
    }
`;

export const createStudent = gql`
    mutation createTutorByAdmin($tutorInput: UserCreateDTO!) {
        createTutorByAdmin(tutorInput: $tutorInput) {
            id
            firstName
            lastName
            email
            contactNo
            gender
            label
            utc
            utcOffset
            city {
                id
                name
            }
            country {
                id
                name
            }   
        }
    }
`;

export const createTutor = gql`
    mutation createTutorByAdmin($tutorInput: UserCreateDTO!) {
        createTutorByAdmin(tutorInput: $tutorInput) {
            id
            firstName
            lastName
            description
            email
            contactNo
            gender
            avatar
            cnic_image_1
            cnic_image_2
            label
            utc
            utcOffset
            city {
                id
                name
            }
            country {
                id
                name
            }
            language {
                id
                name
            }
            inPerson
            onlineCoaching
            userRole {
                id
                name
            }
            tutorSubjects {
                id
                subject {
                    id
                    name
                }
                tutorSubjectsLevel {
                    id
                    level {
                        name
                    }
                    rate
                }
            }
            tutorEducations {
                educationLevel {
                    name
                }
                degreeProgram {
                    name
                }
                fileLink
            }
            isEmailVerified
            payoneerVerified    
        }
    }
`;

export const updateTutorSubject = gql`
    mutation updateTutorSubject(
        $tutorSubjectId: String!
        $subjectLevelRate: [InputLevelRate!]!
    ) {
        updateTutorSubject(
            tutorSubjectId: $tutorSubjectId
            subjectLevelRate: $subjectLevelRate
        ) {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                id
                level {
                    name
                }
                rate
            }
        }
    }
`;

export const blockAUser = gql`
    mutation blockAUser($userId: String!) {
        blockAUser(userId: $userId)
    }
`;

export const unblockAUser = gql`
    mutation unblockAUser($userId: String!) {
        unblockAUser(userId: $userId)
    }
`;

export const removeTutorSubject = gql`
    mutation removeTutorSubject($tutorSubjectId: String!) {
        removeTutorSubject(tutorSubjectId: $tutorSubjectId) {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                id
                level {
                    name
                }
                rate
            }
        }
    }
`;

export const addTutorSubject = gql`
    mutation addTutorSubject($tutorSubject: InputTutorSubject!) {
        addTutorSubject(tutorSubject: $tutorSubject) {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                id
                level {
                    name
                }
                rate
            }
        }
    }
`;

export const addTutorEducation = gql`
    mutation addTutorEducation($tutorEducation: InputTutorEducation!) {
        addTutorEducation(tutorEducation: $tutorEducation) {
            id
            educationLevel {
                id
                name
            }
            degreeProgram {
                id
                name
            }
        }
    }
`;

export const removeTutorEducation = gql`
    mutation removeTutorEducation($tutorEducationId: String!) {
        removeTutorEducation(tutorEducationId: $tutorEducationId) {
            id
            educationLevel {
                id
                name
            }
            degreeProgram {
                id
                name
            }
        }
    }
`;

export const updateMySchedule = gql`
    mutation updateMySchedule($updateMyScheduleVar: [UpdateScheduleInput!]!) {
        updateMySchedule(updateScheduleInput: $updateMyScheduleVar)
    }
`;

export const massPayout = gql`
    mutation massPayout($massPayout: [PayoutInput!]!) {
        massPayout(massPayout: $massPayout)
    }
`;

export const markWithdrawRequestsAsPaid = gql`
    mutation markWithdrawRequestsAsPaid($withdrawRequestIds: [String!]!) {
        markWithdrawRequestsAsPaid(withdrawRequestIds: $withdrawRequestIds)
    }
`;

export const removeTutorIdentityDocumentAdmin = gql`
    mutation removeTutorIdentityDocumentAdmin(
        $tutorId: String!
        $cnicImage1: String!
        $cnicImage2: String!
    ) {
        removeTutorIdentityDocumentAdmin(
            tutorId: $tutorId
            cnicImage1: $cnicImage1
            cnicImage2: $cnicImage2
        ) {
            id
            firstName
            lastName
            avatar
            qualification
            inPerson
            onlineCoaching
            contactNo
            identityDocuments
            overallRating
            dob
            gender
            description
            label
            utc
            utcOffset
            email
            cnic_image_1
            cnic_image_2
        }
    }
`;

export const createBooking = gql`
    mutation createBooking($booking: CreateBookingInput!) {
        createBooking(createBookingInput: $booking) {
            id
            tutor {
                id
            }
            student {
                id
            }
            subject {
                id
            }
            level {
                id
            }
            totalDuration
            startTime
            endTime
            orderAmount
            tutorAmount
            paymentType
            orderNo
            createdAt
        }
    }
`;
export const updateTutorTimezone = gql`
    mutation updateTimeZone($timezone: UpdateTimzoneDTO!) {
        updateTimeZone(timezone: $timezone)
    }
`;

export const addTutorEducationByAdmin = gql`
    mutation addTutorEducationByAdmin($userId: String!, $tutorEducation: InputTutorEducation!){
        addTutorEducationByAdmin(userId: $userId, tutorEducation: $tutorEducation){
            id
            educationLevel{
                name
            }
        }
    }
`;

export const removeTutorEducationByAdmin = gql`
    mutation removeTutorEducationByAdmin($userId: String!, $tutorEducationId: String!){
        removeTutorEducationByAdmin(userId: $userId, tutorEducationId: $tutorEducationId){
            id
            fileLink
        }
    }
`;
export const updateTutorVerify = gql`
    mutation updateTutorVerify($isVerified: Boolean!, $tutorId: String!) {
        updateTutorVerify(tutorId: $tutorId, isVerified: $isVerified)
    }
`;
export const changeTutorStatus = gql`
    mutation changeTutorStatus($status: Boolean!, $userId: String!) {
        changeTutorStatus(userId: $userId, status: $status)
    }
`;
export const updateBookingStatus = gql`
    mutation updateBookingStatus($bookingId: String!, $status: String!) {
        updateBookingStatus(bookingId: $bookingId, status: $status)
    }
`;

export const resetPassword = gql`
    mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
        resetPassword(resetPasswordInput: $resetPasswordInput)
    }
`;

export const getMySchedule = gql`
    query getMySchedule($tutorId: String!) {
        getMyScheduleAdmin(tutorId: $tutorId) {
            id
            day
            isAvailable
            slot {
                id
                startTime
                endTime
            }
        }
    }
`;

export const getAdminWallet = gql`
    query getAdminWallet($limit: Float!, $pageIndex: Float!) {
        getAdminWallet(limit: $limit, pageIndex: $pageIndex) {
            data {
                id
                amount
                status
                createdAt
                booking {
                    id
                    orderNo
                }
            }
            count
        }
    }
`;

export const transactions = gql`
    query transactions($limit: Float!, $searchTerm: String!, $minPrice: Float!, $maxPrice: Float!, $order: String!, $orderBy: String!, $pageIndex: Float!){
        transactions(limit: $limit, pageIndex: $pageIndex, minPrice: $minPrice, maxPrice: $maxPrice, order: $order, orderBy: $orderBy, searchTerm: $searchTerm){
            count
            transactions{
                batch_number
                amount
                totalAuthorizedAmount
                transactionId
                transactionDate
                status
                bookings{
                    booking{
                        student{
                            firstName
                            lastName
                        }
                    }
                }
                currency
            }
        }
    }
`;

export const requestWithdraw = gql`
    query findAllWithDraw($limit: Float!, $pageIndex: Float!) {
        findAllWithDraw(limit: $limit, pageIndex: $pageIndex) {
            data {
                id
                status
                amount
                createdAt
                updatedAt
                tutor {
                    id
                    firstName
                    lastName
                    email
                    payoneerEmail
                    paymentMethod
                    tutorBankDetails{
                        id
                        accountTitle
                        accountNumber
                        iban
                        bankName
                        branchName
                        swiftCode
                        ukSortCode
                        ifscCode
                        institutionNumber
                        transitNumber
                        accountType

                    }
                }
                
            }
            count
        }
    }
`;

export const getMyBookingsStudent = gql`
    query getMyBookingsStudent(
        $status: String!
        $date: Float!
        $tutor: String!
    ) {
        getMyBookingsStudent(status: $status, date: $date, tutor: $tutor) {
            id
            tutor {
                id
                firstName
                lastName
            }
            student {
                id
                firstName
                lastName
            }
            subject {
                id
                name
            }
            level {
                id
                name
            }
            totalDuration
            startTime
            endTime
            orderAmount
            tutorAmount
            paymentType
            orderNo
            createdAt
            status
        }
    }
`;

export const getMyBookingsTutor = gql`
    query getMyBookingsTutor($status: String!, $date: Float!, $tutor: String!) {
        getMyBookingsTutor(status: $status, date: $date, tutor: $tutor) {
            id
            tutor {
                id
                firstName
                lastName
            }
            student {
                id
                firstName
                lastName
            }
            subject {
                id
                name
            }
            level {
                id
                name
            }
            totalDuration
            startTime
            endTime
            orderAmount
            tutorAmount
            paymentType
            orderNo
            createdAt
            status
        }
    }
`;

export const getMyBookingsAdmin = gql`
    query getMyBookingsAdmin(
        $status: String
        $date: Float
        $tutor: String
        $subject: String
        $limit: Float!
        $pageIndex: Float!
    ) {
        getMyBookingsAdmin(
            status: $status
            date: $date
            tutor: $tutor
            subject: $subject
            limit: $limit
            pageIndex: $pageIndex
        ) {
            data {
                isGeneratedByAdmin
                id
                date
                batch_number
                tutor {
                    id
                    firstName
                    lastName
                }
                student {
                    id
                    firstName
                    lastName
                }
                subject {
                    id
                    name
                }
                level {
                    id
                    name
                }
                totalDuration
                startTime
                payVia
                endTime
                orderAmount
                tutorAmount
                paymentType
                reasonForCancellation
                orderNo
                createdAt
                status
            }
            count
        }
    }
`;

export const getMyBookingsAdminNew = gql`
    query getMyBookingsAdminNew(
        $status: String
        $date: Float
        $tutor: String
        $subject: String
        $limit: Float!
        $pageIndex: Float!
    ) {
        getMyBookingsAdminNew(
            status: $status
            date: $date
            tutor: $tutor
            subject: $subject
            limit: $limit
            pageIndex: $pageIndex
        ) {
            data {
               bookings{
                id
                batch_number
                tutor {
                    id
                    firstName
                    lastName
                }
                student {
                    id
                    firstName
                    lastName
                }
                subject {
                    id
                    name
                }
                level {
                    id
                    name
                }
                totalDuration
                startTime
                endTime
                orderAmount
                tutorAmount
                paymentType
                reasonForCancellation
                orderNo
                createdAt
                status
               }
               batchNumber
            }
            count
        }
    }
`;

export const getSlotsByDay = gql`
    query getSlotsByDay($tutorId: String!, $day: Float!) {
        getSlotsByDay(tutorId: $tutorId, timestampToExtractDay: $day) {
            id
            tutor {
                email
            }
            day
            slot {
                id
                startTime
                endTime
            }
            isAvailable
            generatedSlots
        }
    }
`;

export const getSlotsByDay2 = gql`
    query getSlotsByDayByAdmin($tutorId: String!, $day: Float!, $studentId: String!, $offset: Float!) {
        getSlotsByDayByAdmin(tutorId: $tutorId, timestampToExtractDay: $day, studentId: $studentId, offset: $offset)
    }
    `;
// momentDate
// momentUTCDate
// momentOffset
// timestampFormat
// timestampValueOf
// timestampOffsetFormat
// timestampOffset
// timestampUTCOffset
// dayOfWeek
// time12am
// time12amUTC

export const adminTutorList = gql`
    query getAllTutors(
        $status: String
        $criteria: String
        $searchTerm: String
        $limit: Float
        $pageIndex: Float
    ) {
        getAllTutors(
            status: $status
            criteria: $criteria
            searchTerm: $searchTerm
            limit: $limit
            pageIndex: $pageIndex
        ) {
            users {
                id
                firstName
                lastName
                avatar
                qualification
                inPerson
                onlineCoaching
                contactNo
                identityDocuments
                overallRating
                dob
                gender
                description
                label
                utc
                utcOffset
                email
                isVerified
                isEmailVerified
                tutorSubjects {
                    id
                    subject {
                        id
                        name
                    }
                    tutorSubjectsLevel {
                        level {
                            name
                        }
                        rate
                    }
                }
                createdAt
            }
            count
        }
    }
`;
export const getAllTutorsByAdmin = gql`
    query getAllTutorsByAdmin(
        $status: String
        $criteria: String
        $searchTerm: String
        $limit: Float
        $pageIndex: Float
    ) {
        getAllTutorsByAdmin(
            status: $status
            criteria: $criteria
            searchTerm: $searchTerm
            limit: $limit
            pageIndex: $pageIndex
        ) {
            users {
                id
                firstName
                lastName
                avatar
                qualification
                inPerson
                onlineCoaching
                contactNo
                identityDocuments
                overallRating
                dob
                gender
                description
                label
                utc
                utcOffset
                email
                isVerified
                isBlocked
                isEmailVerified
                isPhoneVerified
                tutorSubjects {
                    id
                    subject {
                        id
                        name
                    }
                    tutorSubjectsLevel {
                        level {
                            name
                        }
                        rate
                    }
                }
                createdAt
            }
            count
        }
    }
`;

export const createTutorSubjectByAdmin = gql`
    mutation createTutorSubjectByAdmin($tutorSubject: InputTutorSubject!, $userId: String!){
        createTutorSubjectByAdmin(tutorSubject: $tutorSubject, userId: $userId){
            id
        }
    }
`;

export const unverifiedTutorsList = gql`
    query getUnverifiedTutors(
        $searchTerm: String
        $limit: Float
        $pageIndex: Float
    ) {
        getUnverifiedTutors(
            searchTerm: $searchTerm
            limit: $limit
            pageIndex: $pageIndex
        ) {
            users {
                id
                firstName
                lastName
                avatar
                qualification
                inPerson
                onlineCoaching
                contactNo
                identityDocuments
                overallRating
                dob
                gender
                description
                label
                utc
                utcOffset
                isVerified
                isEmailVerified
                isPhoneVerified
                email
                tutorSubjects {
                    id
                    subject {
                        id
                        name
                    }
                    tutorSubjectsLevel {
                        level {
                            name
                        }
                        rate
                    }
                }
                createdAt
            }
            count
        }
    }
`;

export const markBookingAsCancelledAdmin = gql`
    mutation markBookingAsCancelledAdmin($bookingId: String!) {
        markBookingAsCancelledAdmin(bookingId: $bookingId)
    }
`;

export const adminStudentList = gql`
    query getAllStudents(
        $searchTerm: String!
        $status: String!
        $limit: Float!
        $pageIndex: Float!
    ) {
        getAllStudents(
            searchTerm: $searchTerm
            status: $status
            limit: $limit
            pageIndex: $pageIndex
        ) {
            users {
                id
                firstName
                lastName
                avatar
                qualification
                inPerson
                isBlocked
                onlineCoaching
                contactNo
                identityDocuments
                overallRating
                dob
                gender
                isVerified
                isEmailVerified
                isPhoneVerified
                description
                label
                utc
                utcOffset
                email
                createdAt
            }
            count
        }
    }
`;

export const unverifiedStudentsList = gql`
    query getUnverifiedStudents(
        $searchTerm: String!
        $limit: Float!
        $pageIndex: Float!
    ) {
        getUnverifiedStudents(
            searchTerm: $searchTerm
            limit: $limit
            pageIndex: $pageIndex
        ) {
            users {
                id
                firstName
                lastName
                avatar
                qualification
                inPerson
                isBlocked
                onlineCoaching
                contactNo
                identityDocuments
                overallRating
                isVerified
                isEmailVerified
                isPhoneVerified
                dob
                gender
                description
                label
                utc
                utcOffset
                email
                createdAt
            }
            count
        }
    }
`;
export const otpVerificationOnly = gql`
    mutation adminOtpVerification(
        $otp: Float!
        $email: String!
        $password: String!
    ) {
        adminOtpVerification(otp: $otp, email: $email, password: $password)
    }
`;

export const sendOtp = gql`
    mutation adminSendOtp($email: String!, $password: String!) {
        adminSendOtp(email: $email, password: $password)
    }
`;

export const getTutorStars = gql`
    query getTutorStars($tutorId: String!) {
        getTutorStars(tutorId: $tutorId) {
            fiveStar
            fourStar
            threeStar
            twoStar
            oneStar
            fiveStarPer
            fourStarPer
            threeStarPer
            twoStarPer
            oneStarPer
        }
    }
`;

export const getAdminStats = gql`
    query getAdminStats {
        getAdminStats {
            totalSessions
            completedSessions
            totalEarnings
            totalHoursOfSessions
            totalAmountInWallet
            studentCount
            tutorsCount
        }
    }
`;

export const removeTutorEducationAdmin = gql`
    mutation removeTutorEducationAdmin($tutorEducationId: String!) {
        removeTutorEducationAdmin(tutorEducationId: $tutorEducationId) {
            id
            educationLevel {
                name
            }
            degreeProgram {
                name
            }
            fileLink
        }
    }
`;
