import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderFiltersComponent } from './order-filters/order-filters.component';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OtpVerifyComponent } from './otp-verify/otp-verify.component';
import { MatButtonModule } from '@angular/material/button';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseAlertModule } from '@fuse/components/alert';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ImageCropComponent } from './image-crop/image-crop.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { OrderStatusDialogComponent } from './order-status-dialog/order-status-dialog.component';
import { AlertBoxComponent } from './alert-box/alert-box.component';
import { ConfirmationBoxComponent } from './confirmation-box/confirmation-box.component';
import { PaymentGatewayGetterComponent } from './payment-gateway-getter/payment-gateway-getter.component';


export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        NgOtpInputModule,
        MatDialogModule,
        FuseAlertModule,
        MatMomentDateModule,
        ImageCropperModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OrderFiltersComponent,
        OtpVerifyComponent,
    ],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ],
    declarations: [OrderFiltersComponent,
        OtpVerifyComponent, ImageCropComponent, OrderStatusDialogComponent, AlertBoxComponent, ConfirmationBoxComponent, PaymentGatewayGetterComponent],
    entryComponents: [OtpVerifyComponent]

})
export class SharedModule {
}
