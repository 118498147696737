import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-gateway-getter',
  templateUrl: './payment-gateway-getter.component.html',
  styleUrls: ['./payment-gateway-getter.component.scss']
})
export class PaymentGatewayGetterComponent implements OnInit {

  paymentVia: string;

  constructor(private dialogRef: MatDialogRef<PaymentGatewayGetterComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onSubmit() {
    this.dialogRef.close({ ...this.data, payVia: this.paymentVia })
  }

  ngOnInit(): void {
  }

}
