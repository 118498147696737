import gql from 'graphql-tag';


export const signInUser = gql`mutation signInUser($loginUserInput:  UserLoginInput!){
    login(loginUserInput: $loginUserInput) {
		user {
            id
            email
            firstName
            lastName
            userRole {
                id
                name
            }
        }
        access_token
    }
}`;

export const signInAdmin = gql`mutation loginAdmin($loginUserInput:  AdminLoginInput!){
    loginAdmin(loginUserInput: $loginUserInput) {
		user {
            id
            name
            avatar
            email
            contactNo
        }
        access_token
    }
}`;

export const signupUser = gql`mutation signUpUser($userSignupInput: UserSignUpInput!){
    signUp(userSignupInput: $userSignupInput) {
		user {
            id
            email
            firstName
            userRole {
                id
                name
            }
        }
        access_token
    }
}`;

export const signupAdminUser = gql`mutation signUpUser($userSignupInput: UserSignUpAdminInput!){
    signupAdmin(userSignupInput: $userSignupInput) 
}`;

export const getLoggedInUser = gql`query getLoggedInUser{
    getLoggedInUser{
        id
        firstName
        lastName
        description
        email
        contactNo
        gender
        avatar
        label
        utc
        city {
            id
            name
        }
        country {
            id
            name
        }
        language {
            id
            name
        }
        inPerson
        onlineCoaching
        userRole {
            id
            name
        }
        tutorSubjects {
            id
            subject {
                id
                name
            }
            tutorSubjectsLevel {
                id
                level {
                    name
                }
                rate
            }
        }
        tutorEducations {
            educationLevel {
                name
            }
            degreeProgram {
                name
            }
        }
    }
}`;
