<div class="confirmation-box-main">
    <div class="close-icon">
        <mat-icon class="icon" (click)="this.onButtonClick(false)">close</mat-icon>
    </div>

    <div class="confirmation-text">
        <span>Are you sure you want to do this?</span>
    </div>


    <div class="confirmation-buttons">
        <button (click)="onButtonClick(true)">Yes</button>
        <button class="no" (click)="onButtonClick(false)">No</button>
    </div>
</div>