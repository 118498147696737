import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { Apollo } from 'apollo-angular';
import { signInAdmin, signInUser, signupAdminUser, signupUser } from './auth.service.queries';
import { User } from '../user/user.types';

@Injectable()
export class AuthService {
    public _authenticated: boolean = false;
    onboardingPending;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private apollo: Apollo,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    set user(user) {
        localStorage.setItem('user',user);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    get user() {
        return localStorage.getItem('user') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(email: string, password: string, isAdmin: boolean): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this.apollo.mutate({
            mutation: signInAdmin,
            variables: {
                loginUserInput: {
                    email: email,
                    password: password,
                    isAdmin: isAdmin
                }
            }
        }).pipe(
            tap(
                (result: any) => {

                    if (result.data['loginAdmin']) {

                        // Store the access token in the local storage
                        // this.accessToken = result.data['loginAdmin'].access_token;


                        // Set the authenticated flag to true
                        // this._authenticated = true;

                        // if (!result.data['login']['user'].firstName) {
                        //     this._onboardingPending = true
                        // }

                        const {
                            qualification,
                            tutionType,
                            tutorSubjects,
                            education,
                            identityDocuments,
                            dob,
                            city,
                            country,
                            gender,
                            description,
                            language,
                            ...other
                        } = result.data['loginAdmin']['user'];
                        // Store the user on the user service
                        // this._userService.user = result.data['loginAdmin'].user;
                        // this._userService.user$
                        // .subscribe((user: User) => {
                        //     console.log(user);
                        //     // Mark for check
                        //     // this._changeDetectorRef.markForCheck();
                        // });
                    }


                    // Return a new observable with the response
                    return of(result);
                }
            )
            // ,catchError( (error) => {
            //     console.log(error);
            //     return of(error);
            // })
        );


    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.clear(); //due to cache issue we are removing all storage information

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(email: string, password: string): Observable<any> {
        return this.apollo.mutate({
            mutation: signupAdminUser,
            variables: {
                userSignupInput: {
                    email: email,
                    password: password,
                }
            }
        });
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
