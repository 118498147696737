<div class="payment-gateway-getter">
    <div class="heading">
        <span>Payment Via</span>
    </div>

    <form>
        <div class="text-field">
            <input placeholder="Enter payment via" type="text" name="paymentvia" [(ngModel)]="paymentVia" />
        </div>

        <div class="submit-button">
            <button (click)="onSubmit()" [disabled]="!this.paymentVia">Submit</button>
        </div>
    </form>
</div>