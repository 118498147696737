import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ConfirmationBoxComponent>) { }

  closeDialog() {
    this.dialogRef.close();
  }

  onButtonClick(isWilling: boolean) {
    this.dialogRef.close({ isWilling });
  }

  ngOnInit(): void {
  }

}
