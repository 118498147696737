import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { user } from 'app/mock-api/common/user/data';
import { getLoggedInUser } from '../auth/auth.service.queries';
import {
    removeTutorSubject,
    getAllCities,
    getAllCountries,
    getAllEducationLevel,
    getAllLanguages,
    getAllSubject,
    getAParticularTutor,
    getBasicAccountDetails,
    getCitiesOfParticularCountry,
    getCommonDropDownData,
    getLevelsOfParticularSubject,
    getProgramsOfParticularEducation,
    getTutorSubjectDetails,
    onboardTutor,
    searchTutorByName,
    searchTutorBySubject,
    updateTutorSubject,
    updateUserDetailAdmin,
    addTutorSubject,
    removeTutorEducation,
    addTutorEducation,
    getTutorEducation,
    getTutorSubjectList,
    resetPassword,
    getMySchedule,
    updateMySchedule,
    getSlotsByDay,
    updateTutorTimezone,
    createBooking,
    getMyBookingsStudent,
    getMyBookingsTutor,
    updateBookingStatus,
    adminTutorList,
    adminStudentList,
    updateTutorVerify,
    getMyBookingsAdmin,
    getAdminWallet,
    getReviews,
    requestWithdraw,
    massPayout,
    otpVerificationOnly,
    sendOtp,
    getTutorStars,
    getAdminStats,
    getAdminTutorSubjectList,
    blockAUser,
    unblockAUser,
    getAParticularStudent,
    changeTutorStatus,
    getAParticularTutorAdmin,
    removeTutorEducationAdmin,
    markBookingAsCancelledAdmin,
    removeTutorIdentityDocumentAdmin,
    markWithdrawRequestsAsPaid,
    getAllSubjectsByGroup,
    createTutor,
    createStudent,
    unverifiedTutorsList,
    unverifiedStudentsList,
    updateTutorReview,
    createReview,
    markBookingAsCancelledStudent,
    updateBookingStatusAfterPayment,
    getSlotsByDay2,
    createMultipleBookingByAdmin,
    changeOrderStatusByAdmin,
    changeOrderStatusUpcommingBatchNumberByAdmin,
    getMyBookingsAdminNew,
    getDraftBookingsAdmin,
    createBookingSessionIdByAdmin,
    transactions,
    getAllTutorsByAdmin,
    updateTutorSubjectByAdmin,
    updateTutorDocumentsByAdmin,
    getTutorScheduleByAdmin,
    updateTutorScheduleByAdmin,
    addTutorEducationByAdmin,
    removeTutorEducationByAdmin,
    removeTutorSubjectByAdmin,
    createTutorSubjectByAdmin
} from './ontute.service.queries';
import { AlertBoxComponent } from 'app/shared/alert-box/alert-box.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationBoxComponent } from 'app/shared/confirmation-box/confirmation-box.component';
import { PaymentGatewayGetterComponent } from 'app/shared/payment-gateway-getter/payment-gateway-getter.component';

@Injectable()
export class OntuteService {
    /**
     * Constructor
     */
    constructor(private apollo: Apollo, private _matDialog: MatDialog) { }

    generateRandomPassword(length) {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let password = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset.charAt(randomIndex);
        }

        return password;
    }

    public getTutorScheduleByAdmin(variables: any) {
        return this.apollo.query({
            query: getTutorScheduleByAdmin,
            variables,
            fetchPolicy: 'network-only'
        })
    }

    public updateTutorScheduleByAdmin(userId: string, updateScheduleInput: any) {
        return this.apollo.mutate({
            mutation: updateTutorScheduleByAdmin,
            variables: { userId, updateScheduleInput }
        })
    }

    public getDraftBookingsAdmin(option: any) {
        return this.apollo.query({
            query: getDraftBookingsAdmin,
            variables: {
                status: option.status,
                date: option.date,
                tutor: option.tutor,
                subject: option.subject,
                limit: option.limit,
                pageIndex: option.pageIndex
            },
            fetchPolicy: 'network-only'
        })
    }

    public createBookingSessionIdByAdmin(batch_number: string) {
        return this.apollo.mutate({
            mutation: createBookingSessionIdByAdmin,
            variables: { batch_number },
            fetchPolicy: 'network-only'
        })
    }

    public createMultipleBookingByAdmin(data: any) {
        return this.apollo.mutate({
            mutation: createMultipleBookingByAdmin,
            variables: data
        })
    }

    public updateBookingStatusAfterPayment(bookingId: string) {
        return this.apollo.mutate({
            mutation: updateBookingStatusAfterPayment,
            variables: {
                bookingId: bookingId,
            },
            fetchPolicy: 'network-only'
        });
    }

    changeOrderStatusByAdmin(variables) {
        return this.apollo.mutate({
            mutation: changeOrderStatusByAdmin,
            variables,
            fetchPolicy: 'network-only'

        })
    }

    openAlertBox(title: string, type: string = 'success') {
        return this._matDialog.open(AlertBoxComponent, {
            data: { title, type }
        })
    }

    changeOrderStatusUpcommingBatchNumberByAdmin(variables: any) {
        return this.apollo.mutate({
            mutation: changeOrderStatusUpcommingBatchNumberByAdmin,
            variables,
            fetchPolicy: 'network-only'
        })
    }


    public getAllSubjects() {
        return this.apollo.query({
            query: getAllSubject,
            fetchPolicy: 'network-only',
        });
    }

    public createReview(review) {
        return this.apollo.mutate({
            mutation: createReview,
            variables: {
                review: review
            },
        });
    }

    public markBookingAsCancelledStudent(bookingId: string, reason: string) {
        return this.apollo.mutate({
            mutation: markBookingAsCancelledStudent,
            variables: {
                bookingId: bookingId,
                reasonForCancellation: reason
            },
        });
    }

    public createTutor(onboardDetail: any) {
        return this.apollo.mutate({
            mutation: createTutor,
            variables: {
                tutorInput: onboardDetail,
            },
        });
    }

    public updateTutorReview(reviewId: string, formData: any) {
        return this.apollo.mutate({
            mutation: updateTutorReview,
            variables: {
                reviewId,
                createReviewInput: { ...formData, rating: parseFloat(formData.rating) }
            }
        })
    }

    public createStudent(onboardDetail: any) {
        return this.apollo.mutate({
            mutation: createStudent,
            variables: {
                tutorInput: onboardDetail,
            },
        });
    }

    public getAllSubjectsByGroup() {
        return this.apollo.query({
            query: getAllSubjectsByGroup,
            fetchPolicy: 'network-only',
        });
    }

    public getLevelsOfParticularSubject(subjectId: string) {
        return this.apollo.query({
            query: getLevelsOfParticularSubject,
            variables: {
                subjectId: subjectId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getAllEducationLevel() {
        return this.apollo.query({
            query: getAllEducationLevel,
            fetchPolicy: 'network-only',
        });
    }

    public getProgramsOfParticularEducation(educationId: string) {
        return this.apollo.query({
            query: getProgramsOfParticularEducation,
            variables: {
                educationId: educationId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getBasicAccountDetails() {
        return this.apollo.query({
            query: getBasicAccountDetails,
            fetchPolicy: 'network-only',
        });
    }

    public getAllCountries() {
        return this.apollo.query({
            query: getAllCountries,
            fetchPolicy: 'network-only',
        });
    }

    public getAllCities() {
        return this.apollo.query({
            query: getAllCities,
            fetchPolicy: 'network-only',
        });
    }

    public getCitiesOfParticularCountry(countryId: string) {
        return this.apollo.query({
            query: getCitiesOfParticularCountry,
            variables: {
                countryId: countryId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public updateTutorDocumentsByAdmin(variables: any) {
        return this.apollo.mutate({
            mutation: updateTutorDocumentsByAdmin,
            variables,
        })
    }

    public getAllLanguages() {
        return this.apollo.query({
            query: getAllLanguages,
            fetchPolicy: 'network-only',
        });
    }

    public getCommonDropDownData() {
        return this.apollo.query({
            query: getCommonDropDownData,
            fetchPolicy: 'network-only',
        });
    }



    public adminTutorList(status: string,
        criteria: string,
        searchTerm: string,
        limit: number,
        pageIndex: number) {

        const options = {};

        if (status) { options['status'] = status; };
        if (searchTerm) { options['searchTerm'] = searchTerm; };
        if (criteria) { options['criteria'] = criteria; };
        if (limit) { options['limit'] = limit; };
        if (pageIndex) { options['pageIndex'] = pageIndex; };

        return this.apollo.query({
            query: adminTutorList,
            variables: options,
            fetchPolicy: 'network-only',
        });
    }

    public getAllTutorsByAdmin(status: string,
        criteria: string,
        searchTerm: string,
        limit: number,
        pageIndex: number) {

        const options = {};

        if (status) { options['status'] = status; };
        if (searchTerm) { options['searchTerm'] = searchTerm; };
        if (criteria) { options['criteria'] = criteria; };
        if (limit) { options['limit'] = limit; };
        if (pageIndex) { options['pageIndex'] = pageIndex; };

        return this.apollo.query({
            query: getAllTutorsByAdmin,
            variables: options,
            fetchPolicy: 'network-only',
        });
    }

    updateTutorSubjectByAdmin(variables: any) {
        return this.apollo.mutate({
            mutation: updateTutorSubjectByAdmin,
            variables,
        })
    }

    removeTutorSubjectByAdmin(variables: any) {
        return this.apollo.mutate({
            mutation: removeTutorSubjectByAdmin,
            variables
        })
    }

    public getUnverifiedTutorsList(searchTerm: string, limit: number, pageIndex: number) {
        const options = {};
        if (!searchTerm) {
            if (searchTerm == '') options['searchTerm'] = '';
        }
        if (searchTerm !== '' && searchTerm !== null) { options['searchTerm'] = searchTerm; };
        if (limit) { options['limit'] = limit; };
        if (pageIndex) { options['pageIndex'] = pageIndex; };

        return this.apollo.query({
            query: unverifiedTutorsList,
            variables: options,
            fetchPolicy: 'network-only'
        })
    }

    public createTutorSubjectByAdmin(tutorSubject: any, userId: string) {
        return this.apollo.mutate({
            mutation: createTutorSubjectByAdmin,
            variables: { tutorSubject, userId }
        })
    }

    public openPaymentViaModel(data?: any) {
        return this._matDialog.open(PaymentGatewayGetterComponent, {
            data,
            // disableClose: true
        });
    }

    public getUnverifiedStudentsList(searchTerm: string, limit: number, pageIndex: number) {
        const options = {
            searchTerm: searchTerm == '' ? '' : '@'
        };
        if (searchTerm !== '' && searchTerm !== null) { options['searchTerm'] = searchTerm; };

        if (searchTerm) { options['searchTerm'] = searchTerm; };
        if (limit) { options['limit'] = limit; };
        if (pageIndex) { options['pageIndex'] = pageIndex; };

        return this.apollo.query({
            query: unverifiedStudentsList,
            variables: options,
            fetchPolicy: 'network-only'
        })
    }


    public getStudents(options) {
        return this.apollo.query({
            query: adminStudentList,
            variables: {
                status: options.status,
                searchTerm: options.subject,
                limit: options.limit,
                pageIndex: options.pageIndex
            },
            fetchPolicy: 'network-only',
        });
    }

    public markBookingAsCancelledAdmin(bookingId: string) {
        return this.apollo.mutate({
            mutation: markBookingAsCancelledAdmin,
            variables: {
                bookingId: bookingId,
            },
        });
    }

    public removeTutorIdentityDocumentAdmin(tutorId: string, cnicImage1: string, cnicImage2: string) {
        return this.apollo.mutate({
            mutation: removeTutorIdentityDocumentAdmin,
            variables: {
                tutorId: tutorId,
                cnicImage1: cnicImage1,
                cnicImage2: cnicImage2
            },
            fetchPolicy: 'network-only'
        });
    }

    public getAParticularTutor(tutorId: string) {
        return this.apollo.query({
            query: getAParticularTutor,
            variables: {
                tutorId: tutorId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getAParticularTutorAdmin(tutorId: string) {
        return this.apollo.query({
            query: getAParticularTutorAdmin,
            variables: {
                tutorId: tutorId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getAParticularStudent(studentId: string) {
        return this.apollo.query({
            query: getAParticularStudent,
            variables: {
                studentId: studentId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getReviews(tutorId: string) {
        return this.apollo.query({
            query: getReviews,
            variables: {
                tutorId: tutorId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public searchTutorBySubject(subjectName: string) {
        return this.apollo.query({
            query: searchTutorBySubject,
            variables: {
                subjectName: subjectName,
            },
            fetchPolicy: 'network-only',
        });
    }

    public searchTutorByName(tutorName: string) {
        return this.apollo.query({
            query: searchTutorByName,
            variables: {
                tutorName: tutorName,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getTutorSubjectDetails(tutorSubjectId: string) {
        return this.apollo.query({
            query: getTutorSubjectDetails,
            variables: {
                tutorSubjectId: tutorSubjectId,
            },
            fetchPolicy: 'network-only',
        });
    }


    public removeTutorSubject(tutorSubjectId: string) {
        return this.apollo.mutate({
            mutation: removeTutorSubject,
            variables: {
                tutorSubjectId: tutorSubjectId,
            },
            fetchPolicy: 'network-only'
        });
    }

    public blockAUser(userId: string) {
        return this.apollo.mutate({
            mutation: blockAUser,
            variables: {
                userId: userId,
            },
            fetchPolicy: 'network-only'
        });
    }

    public unblockAUser(userId: string) {
        return this.apollo.mutate({
            mutation: unblockAUser,
            variables: {
                userId: userId,
            },
            fetchPolicy: 'network-only'

        });
    }



    public removeTutorEducation(tutorEducationId: string) {
        return this.apollo.mutate({
            mutation: removeTutorEducation,
            variables: {
                tutorEducationId: tutorEducationId,
            },
            fetchPolicy: 'network-only'
        });
    }


    public getTutorEducation() {
        return this.apollo.query({
            query: getTutorEducation,
            fetchPolicy: 'network-only',
        });
    }

    public getAdminTutorSubjectList() {
        return this.apollo.query({
            query: getAdminTutorSubjectList,
            fetchPolicy: 'network-only',
        });
    }


    public getMySchedule(tutorId) {
        return this.apollo.query({
            query: getMySchedule,
            variables: {
                tutorId: tutorId,
            },
            fetchPolicy: 'network-only',
        });
    }


    public getAdminWallet(options) {
        return this.apollo.query({
            query: getAdminWallet,
            variables: {
                limit: options.limit,
                pageIndex: options.pageIndex
            },
            fetchPolicy: 'network-only',
        });
    }

    public getSlotsByDay(params: {
        tutorId: string;
        timestampToExtractDay: number;
    }) {
        return this.apollo.query({
            query: getSlotsByDay,
            variables: {
                tutorId: params.tutorId,
                day: params.timestampToExtractDay,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getSlotsByDay2(params: {
        tutorId: string;
        timestampToExtractDay: number;
        studentId: string;
        offset: any;
    }) {
        return this.apollo.query({
            query: getSlotsByDay2,
            variables: {
                tutorId: params.tutorId,
                day: params.timestampToExtractDay,
                studentId: params?.studentId,
                offset: params.offset
            },
            fetchPolicy: 'network-only',
        });
    }

    public updateMySchedule(updateMyScheduleVar: any) {
        return this.apollo.mutate({
            mutation: updateMySchedule,
            variables: {
                updateMyScheduleVar: updateMyScheduleVar,
            },

        });
    }

    public massPayout(payouts: any) {
        return this.apollo.mutate({
            mutation: massPayout,
            variables: {
                massPayout: payouts,
            },
        });
    }

    public markWithdrawRequestsAsPaid(payouts: any) {
        return this.apollo.mutate({
            mutation: markWithdrawRequestsAsPaid,
            variables: {
                withdrawRequestIds: payouts,
            },
        });
    }

    public createBooking(booking: any) {
        return this.apollo.mutate({
            mutation: createBooking,
            variables: {
                booking: booking,
            },
        });
    }

    public getLoggedInUser() {
        return this.apollo.query({
            query: getLoggedInUser,
            variables: {
            },
            fetchPolicy: 'network-only',
        });
    }

    public requestWithdraw(option) {
        return this.apollo.query({
            query: requestWithdraw,
            variables: {
                limit: option.limit,
                pageIndex: option.pageIndex
            },
            fetchPolicy: 'network-only',
        });
    }

    public getMyBookingsStudent(option) {
        return this.apollo.query({
            query: getMyBookingsStudent,
            variables: {
                status: option.status,
                date: option.date,
                tutor: option.tutor,
                subject: option.subject
            },
            fetchPolicy: 'network-only',
        });
    }

    public getMyBookingsTutor(option) {
        return this.apollo.query({
            query: getMyBookingsTutor,
            variables: {
                status: option.status,
                date: option.date,
                tutor: option.tutor,
                subject: option.subject
            },
            fetchPolicy: 'network-only',
        });
    }

    public getMyBookingsAdmin(option) {
        return this.apollo.query({
            query: getMyBookingsAdmin,
            variables: {
                status: option.status,
                date: option.date,
                tutor: option.tutor,
                subject: option.subject,
                limit: option.limit,
                pageIndex: option.pageIndex
            },
            fetchPolicy: 'network-only',
        });
    }

    public transactions(
        searchTerm: string,
        limit: number,
        pageIndex: number,
        order?: string,
        orderBy?: string,
        minPrice?: any,
        maxPrice?: any,
    ): any {
        const options = {
            order,
            orderBy,
            minPrice: parseFloat(minPrice),
            maxPrice: parseFloat(maxPrice),
            pageIndex,
            searchTerm,
            limit
        };

        // if (searchTerm) { options['searchTerm'] = searchTerm; };
        // if (limit) { options['limit'] = limit; };
        // if (pageIndex) { options['pageIndex'] = pageIndex; };

        return this.apollo.query({
            query: transactions,
            variables: options,
            fetchPolicy: 'network-only',
        });
    }


    public getMyBookingsAdminNew(option: any) {
        return this.apollo.query({
            query: getMyBookingsAdminNew,
            variables: {
                status: option.status,
                date: option.date,
                tutor: option.tutor,
                subject: option.subject,
                limit: option.limit,
                pageIndex: option.pageIndex
            },
        })
    }

    public updateTutorTimezone(timezoneParams: any) {
        return this.apollo.mutate({
            mutation: updateTutorTimezone,
            variables: {
                timezone: timezoneParams,
            },
            fetchPolicy: 'network-only',
        });
    }

    public addTutorEductionByAdmin(tutorEducation: any, userId: string) {
        return this.apollo.mutate({
            mutation: addTutorEducationByAdmin,
            variables: {
                tutorEducation,
                userId
            }
        })
    }

    openConfirmationModel() {
        return this._matDialog.open(ConfirmationBoxComponent);
    }

    public removeTutorEducationByAdmin(tutorEducationId: string, userId: string) {
        return this.apollo.mutate({
            mutation: removeTutorEducationByAdmin,
            variables: { tutorEducationId, userId }
        })
    }

    public updateTutorVerify(tutorId, isVerified: any) {
        return this.apollo.mutate({
            mutation: updateTutorVerify,
            variables: {
                tutorId: tutorId,
                isVerified: isVerified,
            },
            fetchPolicy: 'network-only',
        });
    }

    public updateUserDetail(userId: string, userDetailInput: any) {
        return this.apollo.mutate({
            mutation: updateUserDetailAdmin,
            variables: { userDetailInput, userId }
        })
    }

    public changeTutorStatus(userId, status: any) {
        return this.apollo.mutate({
            mutation: changeTutorStatus,
            variables: {
                userId: userId,
                status: status,
            },
            fetchPolicy: 'network-only',
        });
    }

    public getTutorStars(tutorId: string) {
        return this.apollo.query({
            query: getTutorStars,
            variables: {
                tutorId: tutorId,
            },
            fetchPolicy: 'network-only',
        });
    }

    public updateBookingStatus(bookingId, status) {
        return this.apollo.mutate({
            mutation: updateBookingStatus,
            variables: {
                bookingId: bookingId,
                status: status
            },
            fetchPolicy: 'network-only',
        });
    }

    public otpVerification(otpValue, email, password) {
        return this.apollo.mutate({
            mutation: otpVerificationOnly,
            variables: {
                otp: otpValue,
                email: email,
                password: password
            },
        });
    }

    public sendOtp(email, password) {
        return this.apollo.mutate({
            mutation: sendOtp,
            variables: {
                email: email,
                password: password
            },
        });
    }

    public getAdminStats() {
        return this.apollo.query({
            query: getAdminStats,
            fetchPolicy: 'network-only',
        });
    }

    public removeTutorEducationAdmin(tutorEducationId) {
        return this.apollo.mutate({
            mutation: removeTutorEducationAdmin,
            variables: {
                tutorEducationId: tutorEducationId
            }
        });
    }
}

