
import { Component, OnInit, ViewChild, Inject, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { OntuteService } from 'app/core/ontute/ontute.service';
import { MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class OtpVerifyComponent implements OnInit {
  @ViewChild('signInNgForm') signInNgForm: NgForm;
  config = {
    length: 5,
    autoFocus: true,
  };
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  otpValue = '';
  showAlert: boolean = false;
  showSpinner = false;

  timeLeft: number = 60;
  interval;
  /**
   * Constructor
   */
  constructor(
    private onTuteService: OntuteService,
    public matDialogRef: MatDialogRef<OtpVerifyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.onTuteService.sendOtp(this.data.email, this.data.password).subscribe(
      (data: any) => {
      },
      () => {
        // Set the alert
      }
    );
    this.startTimer();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  // call every second to update timer
  startTimer(): void {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
  }
  resendCode(): void {
    this.onTuteService.sendOtp(this.data.email, this.data.password).subscribe(
      (data: any) => {
        this.timeLeft = 60;

      },
      () => {
        // Set the alert
      }
    );
  }
  /**
   * Sign in
   */
  verifyOtp(): void {
    this.showAlert = false;
    if (this.otpValue) {

      this.onTuteService.otpVerification(parseInt(this.otpValue), this.data.email, this.data.password).subscribe(
        (data: any) => {
          if (data && data.data.adminOtpVerification) {
            this.matDialogRef.close(true);
          }
        },
        () => {
          // Set the alert
          this.showAlert = true;
          this.showSpinner = false;
          this.alert = {
            type: 'error',
            message: 'OTP is not valid. Please try again.'
          };
        }
      );
    }

    // if (data['login'].user.userRole.id === 1) {
    //     // Navigate to the tutor dashboard
    //     this._router.navigateByUrl('tutor/dashboard');
    // }
    // else if (data['login'].user.userRole.id === 2) {
    //     // Navigate to the student dashboard
    //     this._router.navigateByUrl('student/dashboard');
    // }
  }

  ngOnDestroy(): void {

  }

  onOtpChange(value) {
    this.otpValue = value;
  }
}
