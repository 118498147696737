<div class="flex flex-col max-w-200 md:min-w-160 max-h-screen -m-6">
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary"
    >
        <div class="text-lg font-medium">Crop Image</div>
        <button mat-icon-button (click)="close()" [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"
            ></mat-icon>
        </button>
    </div>

    <!-- <div class="flex justify-center">
        <fuse-alert
            class="mt-8 -mb-4"
            *ngIf="showAlert"
            [appearance]="'outline'"
            [showIcon]="false"
            [type]="alert.type"
            [@shake]="alert.type === 'error'"
        >
            {{ alert.message }}
        </fuse-alert>
    </div> -->

    <div class="m-5 flex flex-col">
        <div class="flex justify-center">
            <image-cropper
                class="w-full"
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="3 / 3"
                format="png"
                [resizeToWidth]="256"
                [cropperMinWidth]="256"
                [style.display]="showCropper ? null : 'none'"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady($event)"
                (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
        </div>
        <div class="flex w-full justify-end">
            <button
                class="fuse-mat-button mt-6"
                mat-flat-button
                [color]="'primary'"
				(click)="done()"
            >
                <span> Done </span>
            </button>
        </div>
    </div>
</div>
