<div class="alert-box-main">
    <div class="close-icon">
        <mat-icon class="icon" (click)="this.closeDialog()">close</mat-icon>
    </div>
    <div class="w-full max-w-100 sm:w-100 mx-auto sm:mx-0 flex flex-col gap-2">

        <div class="flex justify-center">
            <img class="w-30"
                [src]="data.type == 'success' ? successIcon : data.type == 'error' ? errorIcon : warningIcon">
        </div>


        <div class="text-2xl mb-3 text-slate-600 text-center font-medium">
            {{data.title}}
        </div>
    </div>
</div>