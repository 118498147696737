<div
    class="flex flex-col items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0"
>
    <div
        class="md:flex md:items-center md:justify-center w-full h-full sm:p-10 sm:rounded-2xl sm:bg-card md:rounded-none"
    >
        <div class="w-full max-w-90 sm:w-90 mx-auto sm:mx-0">
            <!-- Alert -->
            <fuse-alert
                class="mb-2"
                *ngIf="showAlert"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type"
                [@shake]="alert.type === 'error'"
            >
                {{ alert.message }}
            </fuse-alert>

            <div
                class="sm:p-6 sm:rounded-lg border-2 border-slate-200 sm:bg-card"
            >
                <div class="flex w-15">
                    <img src="assets/images/logo/Logo-web-app.svg" />
                </div>
                <!-- Title -->
                <p class="mt-1 mb-3">
                    An OTP has been sent to your registered mobile number.
                    Please enter the OTP to verify.
                </p>
                <!-- Sign in form -->
                <div>
                    <ng-otp-input
                        (onInputChange)="onOtpChange($event)"
                        [config]="config"
                    ></ng-otp-input>
                    <div class="mt-2" #timer>In 00:{{ timeLeft }}</div>
                    <!--  -->

                    <!-- Submit button -->

                    <button
                        class="fuse-mat-button-large w-full mt-6"
                        mat-flat-button
                        [color]="'primary'"
                        [disabled]="(otpValue && otpValue.length < 5) || (otpValue && timeLeft < 1)"
                        (click)="verifyOtp()"
                    >
                        <span>
                            Verify OTP
                            <mat-progress-spinner
                                *ngIf="showSpinner"
                                [diameter]="24"
                                [mode]="'indeterminate'"
                            >
                            </mat-progress-spinner>
                        </span>
                    </button>
                </div>
                <div class="flex flex-col mt-4 font-medium">
                   
                    <div class="flex flex-row mt-2 space-x-1 items-center">
                        <div>
                            <p class="text-sm font-semibold mb-0">
                                OTP not received?
                            </p>
                        </div>
                        <div>
                            <a
                                id="resendCode"
                                (click)="resendCode()"
                                class="text-md font-bold underline"
                                [ngClass]="
                                    timeLeft === 0
                                        ? 'text-[#273f74] pointer-events: auto cursor-pointer'
                                        : 'text-[#ccc9c9] pointer-events-none'
                                "
                                >Resend OTP
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
